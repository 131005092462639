import { CRow, CCol, CContainer, CSpinner } from "@coreui/react";
import React from "react";

const PageLoading = ({text = "Загрузка"}) => {
  return (
    <div className="bg-transparent min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={12} className="text-center">
            <CSpinner color="primary" />
            <br />
            <strong>{text}</strong>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};
export default PageLoading;
