import React from "react";

import {
  mdiAccount,
  mdiCalendarStarFourPoints,
  mdiHome,
  mdiHuman,
  mdiMonitorDashboard,
  mdiQrcodePlus,
  mdiQrcodeScan,
} from "@mdi/js";

const Index = React.lazy(() => import("./views/Index"));
const OrganisationsListView = React.lazy(() =>
  import("./views/organisations/OrganisationsListView")
);
const OrganisationsManageView = React.lazy(() =>
  import("./views/organisations/OrganisationsManageView")
);
const EventsListView = React.lazy(() =>
  import("./views/events/EventsListView")
);
const EventsManageView = React.lazy(() =>
  import("./views/events/EventsManageView")
);
const QrCodeScannerView = React.lazy(() =>
  import("./views/scanner/QrCodeScannerView")
);
const DashboardDetailView = React.lazy(() =>
  import("./views/dashboard/DashboardDetailView")
);
const CheckersListView = React.lazy(() =>
  import("./views/checkers/CheckersListView")
);
const CheckersManageView = React.lazy(() =>
  import("./views/checkers/CheckersManageView")
);
const QrCodeGeneratorListView = React.lazy(() =>
  import("./views/qr-code-generating/QrCodeGeneratorListView")
);
const QrCodeGeneratorManageView = React.lazy(() =>
  import("./views/qr-code-generating/QrCodeGeneratorManageView")
);
const CheckpointListView = React.lazy(() =>
  import("./views/checkpoints/CheckpointListView")
);
const CheckpointManageView = React.lazy(() =>
  import("./views/checkpoints/CheckpointManageView")
);
const Page404 = React.lazy(() => import("./components/pages/PageError"));

export const navbarItems = [
  {
    to: "/dashboard",
    name: "Дашборд",
    permission: "view_dashboard",
    icon: mdiMonitorDashboard,
  },
  {
    to: "/events/list",
    name: "Мероприятия",
    permission: "view_event",
    icon: mdiCalendarStarFourPoints,
  },
  {
    to: "/organisations/list",
    name: "Организации",
    permission: "view_organisation",
    icon: mdiHome,
  },
  {
    to: "/checkers/list",
    name: "Чекеры",
    permission: "view_user",
    icon: mdiAccount,
  },
  {
    to: "/codes/scanner",
    name: "Сканирование",
    permission: "use_checker",
    icon: mdiQrcodeScan,
  },
  {
    to: "/generator/list",
    name: "Генерация QR кодов",
    permission: "view_qrgeneratetask",
    icon: mdiQrcodePlus,
  },
  {
    to: "/checkpoints/list",
    name: "Пункты пропуска",
    permission: "view_checkpoint",
    icon: mdiHuman,
  },
];

export const routes = [
  {
    path: "*",
    name: "Page404",
    element: Page404,
    permission: "allow_any",
  },
  {
    path: "/",
    name: "Index",
    element: Index,
    permission: "allow_any",
  },
  {
    path: "/organisations/list",
    name: "OrganisationsListView",
    element: OrganisationsListView,
    permission: "view_organisation",
  },
  {
    path: "/organisations/create",
    name: "OrganisationsCreateView",
    element: OrganisationsManageView,
    permission: "add_organisation",
  },
  {
    path: "/organisations/:id/edit",
    name: "OrganisationsEditView",
    element: OrganisationsManageView,
    permission: "change_organisation",
  },
  {
    path: "/events/list",
    name: "EventsListView",
    element: EventsListView,
    permission: "view_event",
  },
  {
    path: "/events/create",
    name: "EventsCreateView",
    element: EventsManageView,
    permission: "add_event",
  },
  {
    path: "/events/:id/edit",
    name: "EventsEditView",
    element: EventsManageView,
    permission: "change_event",
  },
  {
    path: "/codes/scanner",
    name: "QrCodeScannerView",
    element: QrCodeScannerView,
    permission: "use_checker",
    allowOffline: true,
  },
  {
    path: "/generator/list",
    name: "QrCodeGeneratorListView",
    element: QrCodeGeneratorListView,
    permission: "view_qrgeneratetask",
  },
  {
    path: "/generator/create",
    name: "QrCodeGeneratorManageView",
    element: QrCodeGeneratorManageView,
    permission: "add_qrgeneratetask",
  },
  {
    path: "/dashboard",
    name: "DashboardDetailView",
    element: DashboardDetailView,
    permission: "view_dashboard",
  },
  {
    path: "/checkers/list",
    name: "CheckersListView",
    element: CheckersListView,
    permission: "view_user",
  },
  {
    path: "/checkers/create",
    name: "CheckersCreateView",
    element: CheckersManageView,
    permission: "add_user",
  },
  {
    path: "/checkers/:id/edit",
    name: "CheckersEditView",
    element: CheckersManageView,
    permission: "change_user",
  },
  {
    path: "/checkpoints/list",
    name: "CheckpointListView",
    element: CheckpointListView,
    permission: "view_checkpoint",
  },
  {
    path: "/checkpoints/create",
    name: "CheckersCreateView",
    element: CheckpointManageView,
    permission: "add_checkpoint",
  },
  {
    path: "/checkpoints/:id/edit",
    name: "CheckersEditView",
    element: CheckpointManageView,
    permission: "change_checkpoint",
  },
];
