import React, { useContext, createContext, useState, useEffect } from "react";

import api from "../utils/api";

const ConnectionContext = createContext();

const ConnectionProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(true);
  const [resReceived, setResReceived] = useState(false);

  const checkConnection = () => {
    api
      .get("check-conn")
      .then((res) => {
        setResReceived(true);
        setIsConnected(true)
      })
      .catch((err) => {
        setResReceived(true);
        setIsConnected(false)
      });
  };

  useEffect(() => {
    const intervalId = setInterval(checkConnection, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <ConnectionContext.Provider value={{ isConnected, resReceived }}>
      {children}
    </ConnectionContext.Provider>
  );
};

export default ConnectionProvider;

export const useConnection = () => {
  return useContext(ConnectionContext);
};
