import React from "react";
import {
  CContainer,
  CHeader,
  CHeaderNav,
  CNavItem,
  CDropdown,
  CDropdownMenu,
  CDropdownToggle,
  CDropdownItem,
  CNavLink,
  CBadge,
  CSpinner,
} from "@coreui/react";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import useScreenType from "react-screentype-hook";
import { useAuth } from "../hooks/AuthProvider";
import { useConnection } from "../hooks/ConnectionProvider";

import Icon from "@mdi/react";
import { mdiAlert, mdiMenu } from "@mdi/js";

import { navbarItems } from "../routes";

const HeaderItem = ({ item, isMobile }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return isMobile ? (
    <CDropdownItem
      className="rounded-0 text-secondary"
      onClick={() => navigate(item.to)}
    >
      {item.icon && <Icon path={item.icon} size={0.8} className="me-2" />}
      {item.name}
    </CDropdownItem>
  ) : (
    <CNavItem>
      <CNavLink
        to={item.to}
        component={NavLink}
        className={
          location.pathname && location.pathname !== "/" &&
          item.to.startsWith("/" + location.pathname.split("/")[1])
            ? "text-primary"
            : "text-secondary"
        }
      >
        {item.name}
        {item.icon && <Icon path={item.icon} size={0.8} className="ms-2" />}
      </CNavLink>
    </CNavItem>
  );
};

const Header = () => {
  const auth = useAuth();
  const screenType = useScreenType();
  const { isConnected } = useConnection();

  const userName = auth.user?.full_name
    ? auth.user?.full_name + " | " + auth.user?.username
    : auth.user?.username;

  const items = navbarItems
    .filter((item) => auth.hasPerms(item.permission))
    .map((item, idx) => (
      <HeaderItem key={idx} item={item} isMobile={screenType.isMobile} />
    ));

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        {screenType.isMobile ? (
          <CDropdown>
            <CDropdownToggle
              color="transparent"
              size="sm"
              className="text-secondary"
              caret={false}
            >
              <Icon path={mdiMenu} size={1} />
            </CDropdownToggle>
            <CDropdownMenu className="rounded-0 p-0">{items}</CDropdownMenu>
          </CDropdown>
        ) : (
          <CHeaderNav className="d-none d-md-flex me-auto">
            {auth.userIsLoading ? <CSpinner size="sm" /> : items}
          </CHeaderNav>
        )}
        <CHeaderNav>
          <CNavItem>
            <CBadge color={isConnected ? "success" : "danger"}>
              {isConnected ? (
                "Соединен с сервером"
              ) : (
                <>
                  <Icon path={mdiAlert} size={0.6} className="me-2" />
                  Автономный режим
                </>
              )}
            </CBadge>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
              <CDropdown>
                <CDropdownToggle
                  color="transparent"
                  size="sm"
                  className="text-secondary"
                  caret={false}
                >
                  {auth.userIsLoading ? (
                    <CSpinner size="sm" />
                  ) : (
                    <span>
                      {screenType.isMobile ? auth.user?.username : userName}
                      <br/>
                      {auth.user?.region_name}
                    </span>
                  )}
                </CDropdownToggle>
                <CDropdownMenu className="rounded-0 p-0">
                  <CDropdownItem disabled={!isConnected} onClick={auth.logout}>
                    Выход из учетной записи
                  </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
          </CNavItem>
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};

export default Header;
