const proto = window.location.protocol;
const host = window.location.hostname;
const port = window.location.port === "3000" ? "8000" : window.location.port;
const usingHttps = proto.toLowerCase().endsWith("s:");

export const backendHost = `${proto}//${host}${port ? `:${port}` : ""}`;
export const wsUrl = `${usingHttps ? "wss" : "ws"}://${host}${
  port ? `:${port}` : ""
}/ws/socket-server/`;

console.log(`using HTTPS: ${usingHttps}`);

export const selectControlStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: "#9da5b1",
    boxShadow: state.isFocused ? "0 0 0 0.25rem rgba(204, 199, 246, 1)" : null,
    borderRadius: "0px",
    width: "100%",
    "&:hover": {
      borderColor: "#9da5b1",
    },
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    borderRadius: "0px",
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    maxHeight: "150px",
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isSelected ? "#321fdb" : "#fff",
  }),
};
