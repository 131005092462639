import React from "react";

import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { useConnection } from "./ConnectionProvider";

import PageError from "../components/pages/PageError";

const PrivateRoute = ({ children, permission, allowOffline = false }) => {
  const { token, hasPerms } = useAuth();
  const { isConnected } = useConnection();
  const location = useLocation();

  if (!isConnected && !allowOffline) {
    return <PageError errCode="NOCONN" />;
  }
  if (!token) {
    const url =
      location.pathname && location.pathname !== "/login"
        ? `/login?next=${location.pathname}`
        : "/login";

    return <Navigate to={url} replace />;
  }

  if (!hasPerms(permission) && permission !== "allow_any") {
    return <PageError errCode="NOTPERMITTED" />;
  }

  return children;
};

export default PrivateRoute;
